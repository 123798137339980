.card-field {
  iframe {
    padding: 30px;
    margin : 20px;
  }
}

.signup-button {
  margin-top: 20px;
}

// .CardElement {
//   fontSize: "16px",
//     color: "#495057",
//     fontFamily: "'Open Sans', sans-serif",
//     display: 'block',
//     boxSizing: 'border-box',
//     width: '90px',
//     margin: '55px',
//     padding: '16px',
//     border: '1px solid #ddd',
//     borderRadius: '4px',
//     background: '#eee',
//     outline: 'none',
//     font: 'inherit',
//     textTransform: 'uppercase',
//     textAlign: 'center',
// }