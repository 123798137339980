.small {
  width: 100px;
}

.small .react-colorful {
  width : 120px;
  height: 120px;
}

.small .react-colorful__hue {
  height: 20px;
}

.color-picker {
  display       : flex;
  flex-direction: column;
  align-items   : center;
  text-align    : center;
}

.color-picker input {
  display       : block;
  box-sizing    : border-box;
  width         : 90px;
  margin        : 20px 55px 0;
  padding       : 6px;
  border        : 1px solid #ddd;
  border-radius : 4px;
  background    : #eee;
  outline       : none;
  font          : inherit;
  text-transform: uppercase;
  text-align    : center;
}

.color-picker input:focus {
  border-color: #4298ef;
}