.card-editor {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f2f2f2;
}

.editor-header {
  padding-bottom: 30px;
}

.card-title {
  padding: 1.25rem;
  padding-bottom: 0px;
  margin: 0px;
}

.fragment-form {
  padding: 16px;

  .delete-fragment-button {

  }
}