@import url('https://fonts.googleapis.com/css?family=Lobster+Two&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

body {
  margin                 : 0;
  padding                : 0;
  color                  : #333333;
  font-size              : 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing : antialiased;
  font-family            : "Lato", "Open Sans", sans-serif;
  min-height                 : 100vh;
  display                : flex;
  flex-direction         : column;
}

#root {
  min-height: 100%;
}

.App {
  min-height: 100%;
  display                : flex;
  flex-direction         : column;
}

.navbar {
  background-color: #879988;
}

#content {
  flex-grow: 1;
}

h1,
h2,
h3,
h4,
h5 {}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.brand-font {
  font-family: "Lobster Two", "Open Sans", sans-serif;
}

.navbar {
  position: sticky !important;
  top     : 0;
  z-index : 10;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}

input[type=file] {
  width: 100%;
}

@media all and (min-width: 480px) {

  form {
    margin   : 0 auto;
    max-width: 400px;
  }
}

//@import url("https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css");
.navbar {
  color: #ede6d2;

  .navbar-toggler-icon {
    fill: #ede6d2;
  }
}

.hidden {
  display: none;
}

footer {
  color           : white;
  background-color: black;
  padding         : 16px;
  margin-top      : 30px;
}