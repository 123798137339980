.card-manager {
  padding-bottom: 30px;
  .card-list {
    .item-content {
      display        : flex;
      flex-direction : column;
      flex-wrap      : nowrap;
      justify-content: space-between;
      align-items    : center;

      .card-title {
        font-family: "Lobster Two", "Open Sans", sans-serif;
        font-size  : 3em;
      }

      .basic-info {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }

      .admin-buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .visits-button {
          margin-left: 16px;
        }
      }
      .qr-code {
        display: flex;
        flex-direction: column;
        width: 200px;
      }
    }
  }

  .add-card {
    width     : 100%;
    margin-top: 16px;
  }
}

@media only screen and (max-width: 800px) {
  .card-manager {
    .card-list {
      .item-content {
        flex-direction: column;
      }
    }
  }
}